import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const StatusCheck = (stat) => {
    if (stat == "accepted") return <CheckCircleIcon fontSize='small' sx={{ color: '#198f51d1' }} />
    return <CancelIcon fontSize='small' color='error' />
}

const LeaveCheck = (leave) => {
    if (leave == "full day")
        return <Stack direction='row' spacing={0.5} alignItems='center'>
            <Typography color='text.secondary' fontSize='0.9rem'>Full Day</Typography>
            <HourglassFullIcon sx={{ color: '#b7b7b7d1', fontSize: '1rem' }} />
        </Stack>
    return <Stack direction='row' spacing={0.5} alignItems='center'>
        <Typography color='text.secondary' fontSize='0.9rem'>Half Day</Typography>
        <HourglassTopIcon sx={{ color: '#b7b7b7d1', fontSize: '1rem'  }} />
    </Stack>
}

export const LeaveAccordion = ({ value }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion sx={{ mb: '0.7rem', '&.MuiAccordion-root': { borderRadius: '5px !important' } }} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Stack direction='row' spacing={10} alignItems='center'>
                    <Typography sx={{ fontSize: '0.9rem', color: 'text.secondary', }}>
                        Taken on {value.StartDate}
                    </Typography>
                    <Tooltip arrow title={`Leave ${value.Status}`}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography sx={{ color: 'text.secondary', fontSize: '0.9rem' }}>Leave Status: </Typography>
                        <Box>{StatusCheck(value.Status)}</Box>
                    </Stack>
                    </Tooltip>
                </Stack>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container mb={1}>
                    <Grid item xs={3.5}><Typography color='text.secondary' fontFamily='Segoe UI' fontSize='0.9rem' fontWeight='700'>Leave Type:</Typography></Grid>
                    <Grid item xs={'auto'}>{LeaveCheck(value.LeaveType)}</Grid>
                </Grid>

                <Grid container mb={1}>
                    <Grid item xs={3.5}><Typography color='text.secondary' fontFamily='Segoe UI' fontSize='0.9rem' fontWeight='700'>Total leave days:</Typography></Grid>
                    <Grid item xs={'auto'}><Typography color='text.secondary' fontSize='0.9rem' fontFamily='Segoe UI'>{value.NoOfDays} day/s</Typography></Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3.5}><Typography color='text.secondary' fontFamily='Segoe UI' fontSize='0.9rem' fontWeight='700'>Reason of leave:</Typography></Grid>
                    <Grid item xs={'auto'}><Typography color='text.secondary' fontSize='0.9rem' fontFamily='Segoe UI'>{value.Reason}</Typography></Grid>
                </Grid>
            </AccordionDetails>
        </Accordion >
    )
}
